<template>
  <router-view />
</template>

<script>
import { ApiService } from '@/Services/ApiService';
import { eventBus } from './events/eventBus';

export default {
  name: "App",
  data() {
    return {
      api: new ApiService()
    }
  },
  watch: {
    async '$route'(to) {
      if (to.query.logout) {
        eventBus.emit('show-loading', true);
        const response = await this.api.post('clients/logout');
        if ('errors' in response) {
          eventBus.emit('show-loading', false);
          this.$router.replace({ path: '/home', query: null });
        } else {
          localStorage.clear();
          localStorage.clear();
          eventBus.emit('show-loading', false);
          this.$router.replace({ path: '/', query: null });
        }
      }
      if (to.query.setup_password_token) {
        const response = await this.api.post('clients/auth/setup_password/verify',{ setup_password_token: to.query.setup_password_token });
        if ('errors' in response) {
          // localStorage.clear();
          // localStorage.clear();
          // this.$router.replace({ path: '/', query: null });
        } else {
          localStorage.setItem('setupPasswordToken', response.setup_password_token);
          this.$router.replace({ path: '/SetupPassword', query: null });
        }
      }
      if (to.query.verify_email_token) {
        const response = await this.api.post('clients/auth/verify/email',{ email_token: to.query.verify_email_token });
        if ('errors' in response) {
          // HANDLE ERROR
        } else {
          this.$router.replace({ path: '/', query: null });
        }
      }
      if (to.query.forget_password_by_email_token) {
        const response = await this.api.post('clients/auth/forget/email/verify',{ email_token: to.query.forget_password_by_email_token });
        if ('errors' in response) {
          localStorage.clear();
          localStorage.clear();
          this.$router.replace({ path: '/', query: null });
        } else {
          localStorage.setItem('forgetToken', response.forget_token);
          this.$router.replace({ path: '/forgetPasswordChange', query: null });
        }
      }
      if (to.query.shared_property_id) {
        localStorage.setItem('shared_property_id', to.query.shared_property_id);
      }
      if (to.query.share_token) {
        localStorage.setItem('share_token', to.query.share_token);
        this.$router.replace({ path: '/register', query: null });
      }
    }
  }
};
</script>
