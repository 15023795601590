import router from '@/router'

export class ApiService {
  constructor(baseUrl = process.env.VUE_APP_BACKEND_URL) {
    this.baseUrl = baseUrl;
  }

  async request(endpoint, options = {}) {
    try {
      const response = await fetch(endpoint, options);

      if (response.ok) {
        return await response.json();
      } else if (response.status == 422) {
        return await response.json();
      } else {
        switch (response.status) {
          case 400:
            return { errors: "Bad Request" };
          case 401:
            localStorage.removeItem("accessToken");
            localStorage.removeItem("accessToken");
            router.push("/");
            return { errors: "Unauthorized" };
          case 403:
            return { errors: "Forbidden" };
          case 404:
            return { errors: "Not Found" };
          case 429:
            return { errors: "Too Many Requests" };
          case 500: {
            const error = await response.json();
            return { errors: error };
          }
          default:
            return { errors: "An unknown error occurred" };
        }
      }
    } catch (error) {
      return error;
    }
  }

  async get(endpoint, filters = {}) {
    const token =
      localStorage.getItem("accessToken") ||
      localStorage.getItem("accessToken");
    const url = new URL(`${this.baseUrl}/${endpoint}`);
    Object.keys(filters).forEach((key) => {
      url.searchParams.append(key, filters[key]);
    });
    return this.request(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: token ?? "",
      },
    });
  }

  async post(endpoint, data = null) {
    const token =
      localStorage.getItem("accessToken") ||
      localStorage.getItem("accessToken");
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: token ?? "",
      },
      body: JSON.stringify(data),
    });
  }

  async put(endpoint, data = null) {
    const token =
      localStorage.getItem("accessToken") ||
      localStorage.getItem("accessToken");
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "accept-language": "en",
        Authorization: token ?? "",
      },
      body: data,
    });
  }

  async patch(endpoint) {
    const token =
      localStorage.getItem("accessToken") ||
      localStorage.getItem("accessToken");
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        "X-HTTP-Method-Override": "PATCH",
        Authorization: token ?? "",
      },
    });
  }

  async delete(endpoint) {
    const token =
      localStorage.getItem("accessToken") ||
      localStorage.getItem("accessToken");
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: token ?? "",
      },
    });
  }

  async download(endpoint) {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${this.baseUrl}/${endpoint}`, {
        method: "GET",
        headers: {
          Authorization: token ?? "",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const contentDisposition = response.headers.get("Content-Disposition");
      let fileName = "downloaded_file.xlsx";

      if (contentDisposition && contentDisposition.includes("filename=")) {
        const matches = contentDisposition.match(/filename="(.+)"/);
        if (matches.length > 1) {
          fileName = matches[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error("Error downloading file", error);
    }
  }
}
